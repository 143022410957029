import React from 'react'

import { IconStyle } from 'lib/utils/typings'

const Arrow1: React.FC<IconStyle> = ({ fill = '#B99A44' }) => {
  return (
    <svg width='20' height='42' viewBox='0 0 20 42' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3 2C3 1.17157 2.32843 0.5 1.5 0.5C0.671573 0.5 0 1.17157 0 2H3ZM18.5692 40.9984C19.3967 40.9602 20.0366 40.2584 19.9984 39.4308L19.376 25.9452C19.3378 25.1177 18.636 24.4778 17.8084 24.516C16.9809 24.5541 16.341 25.256 16.3792 26.0835L16.9324 38.0708L4.9452 38.624C4.11765 38.6622 3.47776 39.364 3.51595 40.1916C3.55415 41.0191 4.25597 41.659 5.08351 41.6208L18.5692 40.9984ZM0 2C0 7.40278 -0.010249 13.1418 2.20891 19.4947C4.43498 25.8673 8.85313 32.7342 17.4894 40.6084L19.5106 38.3916C11.1469 30.7658 7.06502 24.2993 5.04109 18.5053C3.01025 12.6916 3 7.43056 3 2H0Z'
        fill={fill}
      />
    </svg>
  )
}

export default Arrow1
