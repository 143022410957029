import React from 'react'
import styled from 'styled-components'

import tabBarContent from 'content/tab-bar.json'
import Arrow1 from 'components/vectors/Arrow1'
import Arrow2 from 'components/vectors/Arrow2'
import Arrow3 from 'components/vectors/Arrow3'

const TabHelpersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-left: 65px;
  padding-right: 65px;
`

const Anchor = styled.div`
  position: relative;
`

const Arrow1Container = styled.div`
  position: absolute;
  bottom: 10px;
  right: 2px;
`

const Arrow2Container = styled.div`
  position: absolute;
  bottom: 21px;
  left: 23px;
`

const Arrow3Container = styled.div`
  position: absolute;
  bottom: 10px;
  left: 6px;
`

const ArrowDescriptionContainer = styled.div`
  position: absolute;
  word-break: break-word;
  hyphens: auto;

  font-size: 13px;
  font-weight: 300;
  letter-spacing: 3%;
`

const ArrowDescriptionTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3%;
  margin-bottom: 4px;
`

type ArrowDescription = {
  title: string
  style: React.CSSProperties
}

const ArrowDescription: React.FC<ArrowDescription> = ({ title, children, style }) => {
  return (
    <ArrowDescriptionContainer style={style}>
      <ArrowDescriptionTitle>{title}</ArrowDescriptionTitle>
      {children}
    </ArrowDescriptionContainer>
  )
}

export const TabHelpers: React.FC = () => {
  const { tabs } = tabBarContent

  return (
    <TabHelpersContainer>
      <Anchor>
        <Arrow1Container>
          <ArrowDescription
            title={tabs.leftTab.helperTitle}
            style={{
              textAlign: 'left',
              width: 180,
              bottom: 54,
              left: -25,
            }}
          >
            {tabs.leftTab.helperText}
          </ArrowDescription>
          <Arrow1 />
        </Arrow1Container>
      </Anchor>
      <Anchor>
        <Arrow2Container>
          <ArrowDescription
            title={tabs.mainTab.helperTitle}
            style={{
              textAlign: 'center',
              width: 169,
              bottom: 150,
              left: -58,
            }}
          >
            {tabs.mainTab.helperText}
          </ArrowDescription>
          <Arrow2 />
        </Arrow2Container>
      </Anchor>
      <Anchor>
        <Arrow3Container>
          <ArrowDescription
            title={tabs.rightTab.helperTitle}
            style={{
              textAlign: 'right',
              width: 80,
              bottom: 105,
              left: -37,
            }}
          />
          <Arrow3 />
        </Arrow3Container>
      </Anchor>
    </TabHelpersContainer>
  )
}

export default TabHelpers
