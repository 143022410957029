import * as React from 'react'
import styled, { css } from 'styled-components'

type Props = {
  text?: string
  color?: string
  level?: number
}

const TitleContainer = styled.h6<{ color?: string }>`
  font-weight: 700;
  font-size: 28px;
  line-height: 30px;
  letter-spacing: 0.02;

  ${(props) => {
    if (props.color) {
      return css`
        color: ${props.color};
      `
    }
  }}
`

const Title: React.FC<Props> = ({ text, color, children }) => {
  return (
    <TitleContainer color={color}>
      {text}
      {children}
    </TitleContainer>
  )
}

export default Title
