import React from 'react'
import styled from 'styled-components'

import Title from 'components/common/typography/Title'
import Paragraph from 'components/common/typography/Paragraph'
import Spinner from 'components/common/Spinner'

const FullScreenContainer = styled.div`
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ModalRoot = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #ffff;
  padding: 16px;
  padding-top: 24px;
  padding-bottom: 24px;
  margin: 16px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
`

const ConfirmButton = styled.span`
  cursor: pointer;
  font-weight: bolder;
  font-size: 21px;
`

const ActionContainer = styled.span`
  max-height: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

type Content = {
  title: {
    text: string
    color: string
  }
  paragraph: {
    text: string
    color: string
  }
}

export type WelcomeModalProps = {
  content: Content
  onConfirm: () => void
}

export const WelcomeModal: React.FC<WelcomeModalProps> = ({ content, onConfirm }) => {
  const [isClient, setIsClient] = React.useState(false)

  React.useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <FullScreenContainer>
      <ModalRoot>
        <Title color={content.title.color}>{content.title.text}</Title>
        <Paragraph
          style={{
            color: content.paragraph.color,
          }}
        >
          {content.paragraph.text}
        </Paragraph>
        <ActionContainer>
          {!isClient ? (
            <Spinner />
          ) : (
            <ConfirmButton onClick={onConfirm} style={{ color: content.title.color }}>
              OK
            </ConfirmButton>
          )}
        </ActionContainer>
      </ModalRoot>
    </FullScreenContainer>
  )
}

export default WelcomeModal
