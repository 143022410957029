import * as React from 'react'
import styled from 'styled-components'

import MainLayout from 'components/layouts/Main'
import Container from 'components/common/Container'
import HeaderSVG from 'assets/icons/banner.svg'

import indexContent from 'content/index.json'
import appContent from 'content/app.json'
import TabHelpers from 'components/custom/TabHelpers'
import WelcomeModal from 'components/custom/WelcomeModal'
import withAuth from 'lib/utils/withAuth'

const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`

const HeaderContainer = styled(Container)`
  margin-top: 28px;
  margin-bottom: 53px;
`

const IndexPage = () => {
  const [showModal, setShowModal] = React.useState(true)

  const handleConfirm = () => setShowModal(false)

  return (
    <MainLayout
      backgroundColor={appContent.backgroundColor}
      backgroundImage={appContent.backgroundImage}
      content={
        <ContentContainer>
          <HeaderContainer>
            <HeaderSVG />
          </HeaderContainer>

          {showModal && <WelcomeModal content={indexContent.content} onConfirm={handleConfirm} />}
          <TabHelpers />
        </ContentContainer>
      }
      showTabBar={!!appContent.tabBar}
    />
  )
}

export default withAuth(IndexPage)
