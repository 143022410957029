import React from 'react'

import { IconStyle } from 'lib/utils/typings'

const Arrow2: React.FC<IconStyle> = ({ fill = '#B99A44' }) => {
  return (
    <svg
      width='37'
      height='138'
      viewBox='0 0 37 138'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M34.4826 1.77192C34.3566 0.953122 33.5908 0.391475 32.772 0.517442C31.9532 0.643409 31.3915 1.40929 31.5175 2.22808L34.4826 1.77192ZM4.55508 136.903C4.77748 137.701 5.60469 138.167 6.4027 137.945L19.4071 134.321C20.2052 134.098 20.6718 133.271 20.4494 132.473C20.227 131.675 19.3998 131.208 18.6018 131.431L7.04226 134.652L3.82076 123.093C3.59836 122.295 2.77115 121.828 1.97314 122.051C1.17512 122.273 0.70849 123.1 0.930888 123.898L4.55508 136.903ZM31.5175 2.22808C37.4613 40.8632 26.5453 97.0259 4.69355 135.763L7.30648 137.237C29.4547 97.9741 40.5387 41.1368 34.4826 1.77192L31.5175 2.22808Z'
        fill={fill}
      />
    </svg>
  )
}

export default Arrow2
