import React from 'react'

import { IconStyle } from 'lib/utils/typings'

const Arrow3: React.FC<IconStyle> = ({ fill = '#B99A44' }) => {
  return (
    <svg width='30' height='94' viewBox='0 0 30 94' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M30 1.5C30 0.671573 29.3284 0 28.5 0C27.6715 0 27 0.671573 27 1.5H30ZM3.54003 92.8442C3.73014 93.6505 4.5379 94.1501 5.34422 93.96L18.4839 90.862C19.2903 90.6719 19.7898 89.8641 19.5997 89.0578C19.4096 88.2515 18.6018 87.7519 17.7955 87.942L6.11575 90.6958L3.36198 79.0161C3.17188 78.2097 2.36411 77.7102 1.55779 77.9003C0.751475 78.0904 0.251937 78.8982 0.442045 79.7045L3.54003 92.8442ZM27 1.5C27 12.3051 26.9861 54.0961 3.72424 91.711L6.27576 93.289C30.0139 54.9039 30 12.3616 30 1.5H27Z'
        fill={fill}
      />
    </svg>
  )
}

export default Arrow3
